<template>
  <v-card class="my-3">
    <v-card-text>
      <v-form ref="instructionsForm" @submit.prevent="add">
        <v-text-field
          label="Adicionar instrução"
          hint="Pressione ENTER para adicionar"
          v-model="instructionToAdd"
        />
        <div class="mt-3">
          <template v-if="instructions.length > 0">
            <v-chip
              v-for="(instruction, index) in instructions"
              :key="index"
              close
              @click:close="remove(index)"
              class="mr-1 mb-1"
            >
              {{ instruction.description }}
            </v-chip>
          </template>
          <template v-else>
            <p class="font-italic">Nenhuma instrução adicionada.</p>
          </template>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "InstructionsForm",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    instructions: [],
    instructionToAdd: "",
  }),
  watch: {
    value(value) {
      if (value) {
        this.instructions = value;
      }
    },
  },
  mounted() {
    if (this.value) {
      this.instructions = this.value;
    }
  },
  methods: {
    add() {
      if (this.instructionToAdd) {
        this.instructions.push({
          description: this.instructionToAdd,
        });
        this.$emit("input", this.instructions);
        this.instructionToAdd = "";
      }
    },
    remove(index) {
      this.instructions.splice(index, 1);
      this.$emit("input", this.instructions);
    },
  },
};
</script>

<style scoped></style>
